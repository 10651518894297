<template>
    <div class="Cropper">
    <el-dialog
     :visible.sync="dialogVisible"
     width="800px"
     title="请截取图形要素"
     :before-close="handleClose"
     :close-on-click-modal="false">
     <div
     class="cropper-container">
     <div class="cropper-el">
      <vue-cropper
      ref="cropper"
      :img="cropperImg"
      :output-size="option.size"
      :output-type="option.outputType"
      :info="true"
      :can-move="option.canMove"
      :can-move-box="option.canMoveBox"
      :fixed-box="option.fixedBox"
      :auto-crop="option.autoCrop"
      :auto-crop-width="option.autoCropWidth"
      :auto-crop-height="option.autoCropHeight"
      :center-box="option.centerBox"
      :high="option.high"
      :info-true="option.infoTrue"
      @realTime="realTime"
      :enlarge="option.enlarge"
      :fixed="option.fixed"
      :fixed-number="option.fixedNumber"
      :limitMinSize="option.limitMinSize"
      />
     </div>
     </div>
     <div class="dialog-footer">
        <span @click="uploadBth">重新上传</span>
        <span @click="saveImg">提交</span>
     </div>
    </el-dialog>
    </div>
   </template>
     
   <script>
   import { VueCropper } from 'vue-cropper';
   export default {
    name: 'Cropper',
    components: {
    VueCropper
    },
    props: {
    dialogVisible: {
     type: Boolean,
     default: false
    },
    imgType: {
     type: String,
     default: 'blob'
    },
    cropperImg: {
     type: String,
     default: ''
    },
    fixedNumber: {  // 裁剪比例，默认1:1
     type: Array,
     default: function(){
        return [1,1]
     }
    }
    },
    data () {
    return {
     previews: {},
     option: {
     img: '', // 裁剪图片的地址
     size: 1, // 裁剪生成图片的质量
     outputType: 'png', // 裁剪生成图片的格式 默认jpg
     canMove: false, // 上传图片是否可以移动
     fixedBox: false, // 固定截图框大小 不允许改变
     canMoveBox: false, // 截图框能否拖动
     autoCrop: true, // 是否默认生成截图框
     // 只有自动截图开启 宽度高度才生效
     autoCropWidth: 400, // 默认生成截图框宽度
     autoCropHeight: 430, // 默认生成截图框高度
     centerBox: true, // 截图框是否被限制在图片里面
     high: false, // 是否按照设备的dpr 输出等比例图片
     enlarge: 1, // 图片根据截图框输出比例倍数
     mode: '400px 430px', // 图片默认渲染方式
     maxImgSize: 2000, // 限制图片最大宽度和高度
     // limitMinSize: [500,500], // 更新裁剪框最小属性
     limitMinSize: 500, // 更新裁剪框最小属性
     infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
     fixed: false, // 是否开启截图框宽高固定比例 (默认:true)
     fixedNumber: [1, 1] // 截图框的宽高比例 ==>> 这个参数目前没有作用（作者解释的）
    //  fixedNumber: this.zoomScale // 截图框的宽高比例
     },
    };
    },
    methods: {
    // 裁剪时触发的方法，用于实时预览
    realTime (data) {
     this.previews = data;
    },
    // 重新上传
    uploadBth () {
     this.$emit('update-cropper');
    },
    // 取消关闭弹框
    handleClose () {
     this.$emit('colse-dialog', false);
    },
    // 获取裁剪之后的图片，默认blob，也可以获取base64的图片
    saveImg () {
     this.$refs.cropper.getCropData(data => {
      this.uploadFile = data;
      this.$emit('upload-img', data);
     });
     }
    }
   };
   </script>
     
   <style scoped>
/deep/ .el-dialog__wrapper{
    width: 800px;
    height: 600px;
    overflow: hidden;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
} 
/deep/ .el-dialog{
   margin-top: 0!important;
    height: 600px!important;
}
/deep/ .el-dialog__header{
    text-align: center;
}
/deep/ .el-dialog__title{
    font-size: 16px;
    font-weight: 550;
}
.vue-cropper{
    width: 800px!important;
    height: 430px!important;
}  
/deep/ .el-dialog__body{
    padding: 0;
}
/deep/ .dialog-footer{
    width: 245px;
    margin: 46px auto 0;
}
/deep/ .dialog-footer>span{
    display: inline-block;
    width: 110px;
    height: 34px;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
    font-size: 14px;
    border-radius: 6px;
}
/deep/ .dialog-footer>span:nth-of-type(1){
    border: 1px solid rgba(0, 0, 0, 0.45);
    margin-right: 22px;
    color: rgba(0, 0, 0, 0.45);
}
/deep/ .dialog-footer>span:nth-of-type(2){
    background: #EB5E00;
    color: white;
}
   </style>
   